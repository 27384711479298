.barContainer {
    border-left: solid;
    border-left-width: 1px;
    border-left-color: #DDDDDD;
}

.bar {
    white-space: nowrap;
    float: left;
    text-align: right;
    margin-right: 10px;
    height: 25px;
    position: relative;
}

.barText {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
    height: 25px;
    line-height: 25px;
}

.barTextPost {
    white-space: nowrap;
    height: 25px;
    line-height: 25px;
}

.barPost {
    clear: both;
}