.loading {
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.spinnerBorder {
    width: 2rem;
    height: 2rem;
}
