.blocked {
    text-align: center;
    width:100%;
    background-color:red;
    color:#fff;
    font-size: 20px;
    padding: 20px;
}

.blocked p:not(:last-child) {
    margin-bottom: 0.5rem;
}

.blocked p:last-child {
    margin-bottom: 0;
}

.blocked a {
    color: white!important;
    text-decoration: underline!important;
}
