.tipJar {
    max-width: 680px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.firstDropdown, .middleDropdown {
    padding-left: 10px;
    padding-right: 10px;
}

.firstDropdown,
.middleDropdown {
    outline: none;
}

.firstDropdown {
    border-radius: 0.25rem 0 0 0.25rem;
}

.middleDropdown {
    border-radius: 0;
}