body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global Site Color Overrides */

a {
  text-decoration: none!important;
}

a:not(.btn), button.btn-link {
  color: #ae01ff!important;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff!important;
  background-color: #ae01ff!important;
}

.btn-primary {
  background-color: #ae01ff!important;
  border-color: #8000bc!important;
}

.btn-primary:hover, .btn-primary:active {
  background-color: #8000bc!important;
}

.btn-light {
  background-color: #eeeeee!important;
  border-color: #e0e0e0!important;
}

.btn-light:hover, .btn-light:active {
  background-color: #e0e0e0!important;
}

.btn-xs {
  padding: 2px 4px 2px 4px!important;
  font-size: 13px!important;
}

.btn-link.btn-anchor {
  outline: none !important;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  background-color: transparent!important;
}

.alert-danger .btn-link {
  color: #842029!important;
}

/* Sticky Footer */
main {
  padding-top: 60px;
}

/* In XS screen */
@media(max-width:576px) {
  .width-xs-100 {
    width: 100%;
  }
}

.width-sm-50 {
  width: 50%;
}

#noscript {
  margin-top: 100px;
  text-align: center;
  font-size: 1.2rem;
}

#noscript .logo {
  height: 100px;
  margin-bottom: 50px;
}