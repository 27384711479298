.jumbotron {
    padding-bottom: 30px;
}

.iosAppIcon {
    width: 230px;
    height: 230px;
    margin: -115px auto 20px;
    position: relative;
    mask: url(data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20fill-rule%3D%22evenodd%22%20stroke-linejoin%3D%22round%22%20stroke-miterlimit%3D%221.41%22%20clip-rule%3D%22evenodd%22%20version%3D%221.1%22%20viewBox%3D%220%200%20460%20460%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20d%3D%22M460%20316.1c0%205.5%200%2010.99-.04%2016.48-.03%204.63-.08%209.26-.2%2013.88a201.73%20201.73%200%200%201-2.66%2030.21c-1.71%2010-4.9%2019.68-9.47%2028.73a96.6%2096.6%200%200%201-42.23%2042.23%20101.86%20101.86%200%200%201-28.71%209.46c-10%201.65-20.1%202.54-30.22%202.66a649%20649%200%200%201-13.88.21c-5.5.03-10.99.03-16.48.03H143.89c-5.49%200-10.98%200-16.48-.03a648.8%20648.8%200%200%201-13.88-.2%20201.46%20201.46%200%200%201-30.22-2.67c-9.99-1.7-19.67-4.9-28.71-9.46a96.61%2096.61%200%200%201-42.23-42.22%20101.96%20101.96%200%200%201-9.47-28.74%20201.6%20201.6%200%200%201-2.66-30.2c-.12-4.63-.18-9.26-.2-13.89C0%20327.08%200%20321.6%200%20316.1V143.9c0-5.5%200-11%20.04-16.5.02-4.62.08-9.25.2-13.87a201.64%20201.64%200%200%201%202.66-30.2c1.71-10%204.9-19.68%209.47-28.74A96.6%2096.6%200%200%201%2054.6%2012.36%20101.96%20101.96%200%200%201%2083.3%202.9c10-1.64%2020.1-2.53%2030.22-2.66%204.63-.12%209.26-.18%2013.88-.2%205.5-.03%2011-.03%2016.48-.03H316.1c5.5%200%2011%200%2016.49.03a649%20649%200%200%201%2013.88.2c10.12.13%2020.22%201.02%2030.21%202.66%2010%201.71%2019.67%204.9%2028.72%209.46a96.58%2096.58%200%200%201%2042.24%2042.23%20101.92%20101.92%200%200%201%209.46%2028.73%20201.7%20201.7%200%200%201%202.66%2030.21c.12%204.63.18%209.26.2%2013.88.04%205.5.04%2010.99.04%2016.48V316.1z%22%2F%3E%3C%2Fsvg%3E%0A) center/100% 100% no-repeat;
    -webkit-mask: url(data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20fill-rule%3D%22evenodd%22%20stroke-linejoin%3D%22round%22%20stroke-miterlimit%3D%221.41%22%20clip-rule%3D%22evenodd%22%20version%3D%221.1%22%20viewBox%3D%220%200%20460%20460%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20d%3D%22M460%20316.1c0%205.5%200%2010.99-.04%2016.48-.03%204.63-.08%209.26-.2%2013.88a201.73%20201.73%200%200%201-2.66%2030.21c-1.71%2010-4.9%2019.68-9.47%2028.73a96.6%2096.6%200%200%201-42.23%2042.23%20101.86%20101.86%200%200%201-28.71%209.46c-10%201.65-20.1%202.54-30.22%202.66a649%20649%200%200%201-13.88.21c-5.5.03-10.99.03-16.48.03H143.89c-5.49%200-10.98%200-16.48-.03a648.8%20648.8%200%200%201-13.88-.2%20201.46%20201.46%200%200%201-30.22-2.67c-9.99-1.7-19.67-4.9-28.71-9.46a96.61%2096.61%200%200%201-42.23-42.22%20101.96%20101.96%200%200%201-9.47-28.74%20201.6%20201.6%200%200%201-2.66-30.2c-.12-4.63-.18-9.26-.2-13.89C0%20327.08%200%20321.6%200%20316.1V143.9c0-5.5%200-11%20.04-16.5.02-4.62.08-9.25.2-13.87a201.64%20201.64%200%200%201%202.66-30.2c1.71-10%204.9-19.68%209.47-28.74A96.6%2096.6%200%200%201%2054.6%2012.36%20101.96%20101.96%200%200%201%2083.3%202.9c10-1.64%2020.1-2.53%2030.22-2.66%204.63-.12%209.26-.18%2013.88-.2%205.5-.03%2011-.03%2016.48-.03H316.1c5.5%200%2011%200%2016.49.03a649%20649%200%200%201%2013.88.2c10.12.13%2020.22%201.02%2030.21%202.66%2010%201.71%2019.67%204.9%2028.72%209.46a96.58%2096.58%200%200%201%2042.24%2042.23%20101.92%20101.92%200%200%201%209.46%2028.73%20201.7%20201.7%200%200%201%202.66%2030.21c.12%204.63.18%209.26.2%2013.88.04%205.5.04%2010.99.04%2016.48V316.1z%22%2F%3E%3C%2Fsvg%3E%0A) center/100% 100% no-repeat;
}

.iosAppIconBorder {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 230.5 230.5' version='1.1'%3E%3Cpath fill='none' stroke='%23000' stroke-linejoin='round' stroke-miterlimit='1.41' stroke-opacity='.1' stroke-width='1' d='M158.22 230H72.28l-8.22-.01a320 320 0 0 1-6.93-.11c-5.05-.06-10.09-.5-15.07-1.32a50.83 50.83 0 0 1-14.33-4.73 48.2 48.2 0 0 1-21.07-21.06 50.85 50.85 0 0 1-4.72-14.34 100.68 100.68 0 0 1-1.33-15.07c-.06-2.31-.1-4.62-.1-6.93-.02-2.74-.02-5.48-.02-8.22V72.29c0-2.75 0-5.48.02-8.23a320 320 0 0 1 .1-6.93c.06-5.05.5-10.08 1.33-15.06a50.74 50.74 0 0 1 4.72-14.34A48.2 48.2 0 0 1 27.74 6.66a51.02 51.02 0 0 1 14.33-4.73C47.05 1.12 52.09.67 57.14.61a320 320 0 0 1 6.93-.1L72.3.5h85.92l8.23.01a320 320 0 0 1 6.92.1c5.06.07 10.1.5 15.08 1.33 4.98.85 9.81 2.45 14.33 4.72a48.19 48.19 0 0 1 21.07 21.07 50.9 50.9 0 0 1 4.72 14.34c.82 4.98 1.27 10.02 1.33 15.07a320 320 0 0 1 .1 6.92l.01 3.48v94.09l-.01 4.8c-.01 2.3-.05 4.62-.1 6.93-.07 5.05-.5 10.08-1.33 15.07a50.68 50.68 0 0 1-4.72 14.33 48.2 48.2 0 0 1-21.07 21.07 50.8 50.8 0 0 1-14.33 4.73c-4.98.82-10.02 1.26-15.08 1.32a320 320 0 0 1-6.92.1l-8.23.02z' clip-rule='evenodd' vector-effect='non-scaling-stroke'/%3E%3C/svg%3E");
}

.iosAppIconImg, .iosAppIconBorder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.text {
    max-width: 500px;
}

.iPhoneScreenshot, .iPhoneScreenshot img {
    width: 300px;
}