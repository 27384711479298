.axis {
    font-size: 14px;
    position: relative;
    height: 22px;
    border-top: solid;
    border-top-width: 1px;
    border-top-color: #DDDDDD;
}

.axisLabel {
    width: 50px;
    text-align: center;
    position: absolute;
    top: 0;
}

.axisLabelOdd {}
.axisLabelEven {}

@media (max-width: 576px) {
    .axisLabelEven {
        visibility: hidden;
    }
}

.axisHatchMark {
    position: absolute;
    left: 50%;
    background-color: #DDDDDD;
    width: 1px;
    height: 4px;
}

.axisValue {
    margin-top: 4px;
}